
// Antd Global Overrides
.ant-modal-close-x {
    margin-top: -4px !important;
}
.ant-image-preview-img-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.bit-identity-verifcation .ant-table-container table > thead > tr:first-child th,
.bit-identity-verifcation .ant-table-tbody > tr > td {
    padding-left: 3rem !important;
}


// All custom pages global css goes here
.bit-no-padding {
    padding: 0 !important;
}

.bit-no-padding-x {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.bit-no-padding-y {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.bit-no-margin {
    margin: 0 !important;
}

.bit-no-margin-x {
    margin-left: 0 !important ;
    margin-right: 0 !important;
}
.bit-no-margin-y {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.bit-main {
    padding: 1.5rem !important;
    @media(min-width:  @screen-md) {
        padding: 50px !important;
        padding-right: 100px !important; 
    }
}

.bit-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    @media(min-width: @screen-lg) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.bit-col {
    background-color: @white;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, .05);
    display: flex;
    // margin-right: 12px;
    // padding: 1.5rem;
    width: 100%;
    
}

// spacing
.bit-mt {
    margin-top: 1rem !important;
}
.bit-mb {
    margin-bottom: 1rem !important;
}
.bit-mx {
    margin: 0 1rem !important;
}
.bit-my {
    margin: 1rem 0 !important;
}

.bit-pt {
    padding-top: 1rem !important;
}
.bit-pb {
    padding-bottom: 1rem !important;
}
.bit-px {
    padding: 0 1rem !important;
}
.bit-py {
    padding: 1rem 0 !important;
}



// Forms
.bit-form-label .ant-form-item-label {
    height: 40px !important;
}


// Uploads
// .bit-upload .ant-upload-list-item {
    // display: none  !important;
// }
.bit-upload .ant-upload-list-item-name {
    display: none !important;
}