/*fonts*/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap");
/**/

.btn-login {
height: 55px;
background: #800020 !important;
border-radius: 10px !important;
color: #ffffff !important;
}

.btn-login:hover {
  opacity: 0.95;
}

.clear {
  clear: both !important;
  float: none !important;
  margin: 0px !important;
  padding: 0px !important;
  height: 0px !important;
  width: 0px !important;
}
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.clearfix {
  display: inline-block;
}
html[xmlns] .clearfix {
  display: block;
}
* html .clearfix {
  height: 1%;
}

img {
  display: block;
}

.oldBrowser {
  display: none !important;
}
.center {
  width: 960px;
  margin: 0 auto;
  text-align: left;
  position: relative;
}
.inner-center {
  padding: 0 10px;
}
body {
  background: #f3f4f5;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #333333;
  font-weight: 300;
}

a {
  text-decoration: none;
}
.brand-icon {
  color: #ffffff;
  font-weight: bold;
}

.box-right {
  float: right;
}
button,
input[type="submit"] {
  background: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.header-controlMenuButton svg {
  fill: #ffffff;
}

#hit-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 20px;
  height: 100%;
  background-color: transparent;
  display: none;
}

/*HEADER*/
#header {
  position: fixed;
  height: 110px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}
.header-topo {
  background-color: #008658;
  height: 60px;
  position: relative;
}

#header .brand-icon {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 80px;
  height: 24px;
  margin: -12px -40px 0 0;
}
.header-controlMenuButton {
  position: absolute;
  left: 200px;
  height: 60px;
  width: 60px;
  background-color: #00784f;
  text-align: center;
  cursor: pointer;
}
.header-controlMenuButton .spt {
  display: inline-block;
}
.header-controlMenuButton:hover {
  background-color: #005a3b;
}
.header-search {
  width: 260px;
  float: left;
  position: absolute;
  left: 0;
}
.header-search .spt {
  float: left;
  margin: 15px 25px 0 17px;
}
.header-search input {
  float: left;
  font-family: "Roboto", sans-serif;
  color: #9a9a9a;
  font-size: 14px;
  margin-top: 15px;
  border: none;
}
.header-menu {
  height: 50px;
}
#header .menu-module {
  float: left;
  width: 100%;
  height: 50px;
}
#header .menu-module .menu-moduleInner {
  padding: 0 120px 0 280px;
}
#header .menu-module .menu-moduleWrapper {
  overflow-x: scroll;
}
#header .menu-module .menu-moduleBt {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  margin: 0 5px;
}
#header .menu-module .menu-moduleBt-mainBt {
  display: block;
  font-weight: normal;
  text-align: center;
  padding: 15px 0;
  font-size: 14px;
  color: #848484;
}
#header .menu-module .menu-moduleFeed {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 2px;
  left: 0;
  display: none;
  background-color: #008658;
}
#header .menu-module .menu-moduleBt:hover .menu-moduleFeed {
  display: block;
}
#header .menu-module .menu-moduleBt:hover .menu-moduleBt-mainBt {
  color: #333333;
}

.header-menutitle {
  margin: 17px 0 0 20px;
  float: left;
  color: #ffffff;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
}
.header-menutitle_mobile {
  margin: 20px 0 0 5px;
  display: none;
}

.button-menu-mobile {
  height: 80px;
  display: none;
  opacity: 0;
  cursor: pointer;
}
.button-menu-mobile {
  height: 60px;
  float: left;
  width: 40px;
  margin-left: 10px;
  display: none;
}
.button-menu-mobile .box-stripes {
  position: relative;
  width: 20px;
}
.button-menu-mobile span {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  height: 2px;
  top: 0;
}
#header .stripe-top {
  margin-top: -6px;
}
#header .stripe-bottom {
  margin-top: 6px;
}

#header .header-inner {
  /*margin-left: 260px; */
  position: relative;
  background-color: #ffffff;
  border-bottom: 1px solid #e4e5e6;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px 2px 2px 0px rgba(50, 50, 50, 0.1);
  box-shadow: 0px 2px 2px 0px rgba(50, 50, 50, 0.1);
}

/*------*/

/*MENU*/
#menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 100;
  background-color: #ffffff;
  width: 260px;
}
#menu .menu-wrapper-inner {
  padding-top: 110px;
}
#menu .menu-wrapper {
  position: relative;
  overflow-x: hidden;
}
#menu .menu {
  width: 260px;
  float: left;
}
#menu .menu-item {
  position: relative;
}
#menu .menu-item:hover {
  background-color: #fafafa;
}
#menu .menu-item a {
  padding: 15px 30px;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  color: #333333;
  display: block;
}
#menu .menu-item .spt {
  position: absolute;
  right: 25px;
  top: 50%;
  margin-top: -5px;
}
#menu .menu-slider {
  position: relative;
  width: 2000px;
  left: 0;
}
#menu .submenu {
  display: none;
}
#menu .submenu-back {
  border-bottom: 1px solid #eeeeee;
}
#menu .submenu-back .spt {
  left: 25px;
}
#menu .submenu-back a {
  padding-left: 40px;
  font-size: 12px;
}
.header-menu {
  position: relative;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  width: auto;
  white-space: nowrap;
}
.menu-box {
  overflow: auto;
  position: relative;
  height: 100%;
}

.menu-header {
  position: absolute;
  z-index: 10;
  border-bottom: 1px solid #e4e5e6;
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px 2px 2px 0px rgba(50, 50, 50, 0.1);
  box-shadow: 0px 2px 2px 0px rgba(50, 50, 50, 0.1);
}

.shape-remove-shadow_white {
  position: absolute;
  background: #ffffff;
  height: 50px;
  width: 10px;
  right: -3px;
  top: 60px;
}
.shape-remove-shadow_green {
  position: absolute;
  background: #008658;
  height: 60px;
  width: 10px;
  right: -3px;
  top: 0;
}

.menu-mobile-background {
  opacity: 0;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
}

/*menu fechado*/

/********/
#content {
  width: 100%;
  position: relative;
}
#content .content-inner {
  padding: 110px 0 0 260px;
}
#content .content-wrapper {
  padding: 40px;
}
#content .content-box {
  background-color: #ffffff;
  border-bottom: 1px solid #e4e5e6;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.1);
  box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.1);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

#content .content-title {
  font-size: 24px;
  line-height: 28px;
  color: #008658;
  font-weight: 300;
  margin-bottom: 5px;
  display: block;
}
#content .content-titleSection {
  margin-bottom: 15px;
}
#content .breadcrumb-link {
  color: #929394;
  display: inline-block;
  font-size: 14px;
}
#content .breadcrumb-separator {
  display: inline-block;
}
#content .breadcrumb-link_ativo {
  color: #afb0b1;
}
#content .content-boxInner {
  padding: 20px 20px 100px 20px;
}

.button-actions {
  position: fixed;
  bottom: 0;
  right: 40px;
  cursor: pointer;
}
.borderradius .button-actions {
  background-color: #008658;
  width: 56px;
  height: 56px;
  bottom: 20px;
  right: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.6);
}
.borderradius .button-actions .spt {
  display: none;
}
.borderradius .button-actions .plus {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  left: 50%;
  margin: -7px 0 0 -7px;
}
.borderradius .button-actions .plus .vert {
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: #ffffff;
  left: 6px;
  top: 0;
}
.borderradius .button-actions .plus .hor {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  left: 0;
  top: 6px;
}
.borderradius .button-actions:hover {
  background-color: #006945;
}

.borderradius .button-actions.active .vert {
  display: none;
}

.boxWrapperActions {
  position: fixed;
  bottom: 90px;
  right: 80px;
}
.boxWrapperActions-wrapper {
  display: none;
}
.boxWrapperActions-box {
  border: 1px solid #bebebe;
}
.boxWrapperActions .boxWrapperActions-box {
  background-color: #ffffff;
  border-color: #e6e6e6;
  min-width: 130px;
  -webkit-box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.boxWrapperActions .boxWrapperActions-title {
  border-bottom: 1px solid #dcdcdc;
  padding: 12px 20px;
  font-weight: 400;
}
.boxWrapperActions ul {
  padding: 15px 0;
}
.boxWrapperActions ul li:hover {
  background-color: #eeeeee;
}
.boxWrapperActions ul li a {
  padding: 5px 20px;
  display: block;
}

.boxOptions {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 10px;
}
.boxOptions .boxOptions-item_language {
  float: left;
}
.boxOptions .boxOptions-item_user {
  float: left;
}
.boxOptions .boxOptions-button {
  height: 50px;
  padding: 0 10px;
  cursor: pointer;
}
.boxOptions .boxOptions-button.active,
.boxOptions .boxOptions-button:hover {
  background-color: #ececec;
}
.boxOptions .boxOptions-item {
  position: relative;
}
.boxOptionsWrapper {
  position: absolute;
  right: 0;
  top: 60px;
}
.boxOptionsWrapper-container {
  display: none;
}
.boxOptionsWrapper-content {
  background-color: #ffffff;
  border-color: #e6e6e6;
  min-width: 130px;
  -webkit-box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.boxOptionsWrapper-content ul li:hover {
  background-color: #eeeeee;
}
.boxOptionsWrapper-content ul li a {
  padding: 5px 20px;
  display: block;
  font-size: 14px;
}

.loginDiv {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
  border-radius: 10px;
  /* width: 32%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 30px;
  padding-right: 30px;
  margin: -4% 0% 0%;
}

@media screen and (max-width: 768px) {
  #header .brand-icon {
    right: 0;
    margin-right: 20px;
  }
  #header .menu-module .menu-moduleWrapper {
    margin-left: 0 !important;
  }

  #menu {
    z-index: 999;
  }

  #menu {
    left: -260px;
    opacity: 0;
  }
  #hit-menu {
    display: block;
  }
  #header .button-menu-mobile {
    display: block;
    opacity: 1;
  }
  #header .menu-module .menu-moduleInner {
    padding: 0;
  }

  .shape-remove-shadow_green,
  .shape-remove-shadow_white {
    display: none;
  }
  #content .content-inner {
    padding-left: 0 !important;
  }

  #content .content-wrapper {
    padding: 20px 10px;
  }
  .header-menutitle_mobile {
    font-size: 14px;
  }

  .borderradius .button-actions {
    right: 30px;
    width: 40px;
    height: 40px;
  }
  .borderradius .boxWrapperActions {
    bottom: 60px;
    right: 80px;
  }
}

/****************************/
/*RESPOSIVE*/
/****************************/

.resp-box {
  float: left;
  width: 100%;
}

.col-1 {
  width: 100%;
}

.col-2,
.col-1-2,
.col-5-10,
.col-4-8,
.col-3-6,
.col-2-4 {
  width: 50%;
}

.col-3,
.col-1-3,
.col-3-9,
.col-2-6 {
  width: 33.3333%;
}

.col-4,
.col-1-4,
.col-2-8 {
  width: 25%;
}

.col-5,
.col-1-5,
.col-2-10 {
  width: 20%;
}

.col-6,
.col-1-6 {
  width: 16.6666%;
}

.col-7,
.col-1-7 {
  width: 14.2857%;
}

.col-8,
.col-1-8 {
  width: 12.5%;
}

.col-9,
.col-1-9 {
  width: 11.1111%;
}

.col-10,
.col-1-10 {
  width: 10%;
}

.col-2-3 {
  width: 66.6666%;
}

.col-2-5 {
  width: 40%;
}

.col-2-7 {
  width: 28.5714%;
}

.col-2-9 {
  width: 22.2222%;
}

.col-3-4 {
  width: 75%;
}
.col-3-5 {
  width: 60%;
}

.col-3-7 {
  width: 42.8571%;
}
.col-3-8 {
  width: 37.5%;
}

.col-3-10 {
  width: 30%;
}

.col-4-5 {
  width: 80%;
}
.col-4-6 {
  width: 66.6666%;
}
.col-4-7 {
  width: 57.1428%;
}

.col-4-9 {
  width: 44.4444%;
}
.col-4-10 {
  width: 40%;
}

.col-5-6 {
  width: 83.3333%;
}
.col-5-7 {
  width: 71.4285%;
}
.col-5-8 {
  width: 62.5%;
}
.col-5-9 {
  width: 55.5555%;
}

.col-6-7 {
  width: 82.7142%;
}
.col-6-8 {
  width: 75%;
}
.col-6-9 {
  width: 66.6666%;
}
.col-6-10 {
  width: 60%;
}

.col-7-8 {
  width: 87.5%;
}
.col-7-9 {
  width: 77.7777%;
}
.col-7-10 {
  width: 70%;
}

.col-8-9 {
  width: 88.8888%;
}
.col-8-10 {
  width: 80%;
}

.col-9-10 {
  width: 90%;
}

/*--------------	Media queries	------------------*/
/*
	* < 568px
*/
@media screen and (max-width: 35.5em) {
  .col-xs-1 {
    width: 100% !important;
  }

  .col-xs-hide {
    display: none !important;
  }
  .col-xs-show {
    display: block !important;
  }

  .col-xs-2,
  .col-xs-1-2,
  .col-xs-5-10,
  .col-xs-4-8,
  .col-xs-3-6,
  .col-xs-2-4 {
    width: 50%;
  }

  .col-xs-3,
  .col-xs-1-3,
  .col-xs-3-9,
  .col-xs-2-6 {
    width: 33.3333%;
  }

  .col-xs-4,
  .col-xs-1-4,
  .col-xs-2-8 {
    width: 25%;
  }

  .col-xs-5,
  .col-xs-1-5,
  .col-xs-2-10 {
    width: 20%;
  }

  .col-xs-6,
  .col-xs-1-6 {
    width: 16.6666%;
  }

  .col-xs-7,
  .col-xs-1-7 {
    width: 14.2857%;
  }

  .col-xs-8,
  .col-xs-1-8 {
    width: 12.5%;
  }

  .col-xs-9,
  .col-xs-1-9 {
    width: 11.1111%;
  }

  .col-xs-10,
  .col-xs-1-10 {
    width: 10%;
  }

  .col-xs-2-3 {
    width: 66.6666%;
  }

  .col-xs-2-5 {
    width: 40%;
  }

  .col-xs-2-7 {
    width: 28.5714%;
  }

  .col-xs-2-9 {
    width: 22.2222%;
  }

  .col-xs-3-4 {
    width: 75%;
  }
  .col-xs-3-5 {
    width: 60%;
  }

  .col-xs-3-7 {
    width: 42.8571%;
  }
  .col-xs-3-8 {
    width: 37.5%;
  }

  .col-xs-3-10 {
    width: 30%;
  }

  .col-xs-4-5 {
    width: 80%;
  }
  .col-xs-4-6 {
    width: 66.6666%;
  }
  .col-xs-4-7 {
    width: 57.1428%;
  }

  .col-xs-4-9 {
    width: 44.4444%;
  }
  .col-xs-4-10 {
    width: 40%;
  }

  .col-xs-5-6 {
    width: 83.3333%;
  }
  .col-xs-5-7 {
    width: 71.4285%;
  }
  .col-xs-5-8 {
    width: 62.5%;
  }
  .col-xs-5-9 {
    width: 55.5555%;
  }

  .col-xs-6-7 {
    width: 82.7142%;
  }
  .col-xs-6-8 {
    width: 75%;
  }
  .col-xs-6-9 {
    width: 66.6666%;
  }
  .col-xs-6-10 {
    width: 60%;
  }

  .col-xs-7-8 {
    width: 87.5%;
  }
  .col-xs-7-9 {
    width: 77.7777%;
  }
  .col-xs-7-10 {
    width: 70%;
  }

  .col-xs-8-9 {
    width: 88.8888%;
  }
  .col-xs-8-10 {
    width: 80%;
  }

  .col-xs-9-10 {
    width: 90%;
  }
}

/*
	* > 568px e < 768
*/
@media (min-width: 35.5em) and (max-width: 48em) {
  .loginDiv {
    width: unset;
  }
  .col-sm-1 {
    width: 100% !important;
  }

  .col-sm-hide {
    display: none !important;
  }
  .col-sm-show {
    display: block !important;
  }

  .col-sm-2,
  .col-sm-1-2,
  .col-sm-5-10,
  .col-sm-4-8,
  .col-sm-3-6,
  .col-sm-2-4 {
    width: 50%;
  }

  .col-sm-3,
  .col-sm-1-3,
  .col-sm-3-9,
  .col-sm-2-6 {
    width: 33.3333%;
  }

  .col-sm-4,
  .col-sm-1-4,
  .col-sm-2-8 {
    width: 25%;
  }

  .col-sm-5,
  .col-sm-1-5,
  .col-sm-2-10 {
    width: 20%;
  }

  .col-sm-6,
  .col-sm-1-6 {
    width: 16.6666%;
  }

  .col-sm-7,
  .col-sm-1-7 {
    width: 14.2857%;
  }

  .col-sm-8,
  .col-sm-1-8 {
    width: 12.5%;
  }

  .col-sm-9,
  .col-sm-1-9 {
    width: 11.1111%;
  }

  .col-sm-10,
  .col-sm-1-10 {
    width: 10%;
  }

  .col-sm-2-3 {
    width: 66.6666%;
  }

  .col-sm-2-5 {
    width: 40%;
  }

  .col-sm-2-7 {
    width: 28.5714%;
  }

  .col-sm-2-9 {
    width: 22.2222%;
  }

  .col-sm-3-4 {
    width: 75%;
  }
  .col-sm-3-5 {
    width: 60%;
  }

  .col-sm-3-7 {
    width: 42.8571%;
  }
  .col-sm-3-8 {
    width: 37.5%;
  }

  .col-sm-3-10 {
    width: 30%;
  }

  .col-sm-4-5 {
    width: 80%;
  }
  .col-sm-4-6 {
    width: 66.6666%;
  }
  .col-sm-4-7 {
    width: 57.1428%;
  }

  .col-sm-4-9 {
    width: 44.4444%;
  }
  .col-sm-4-10 {
    width: 40%;
  }

  .col-sm-5-6 {
    width: 83.3333%;
  }
  .col-sm-5-7 {
    width: 71.4285%;
  }
  .col-sm-5-8 {
    width: 62.5%;
  }
  .col-sm-5-9 {
    width: 55.5555%;
  }

  .col-sm-6-7 {
    width: 82.7142%;
  }
  .col-sm-6-8 {
    width: 75%;
  }
  .col-sm-6-9 {
    width: 66.6666%;
  }
  .col-sm-6-10 {
    width: 60%;
  }

  .col-sm-7-8 {
    width: 87.5%;
  }
  .col-sm-7-9 {
    width: 77.7777%;
  }
  .col-sm-7-10 {
    width: 70%;
  }

  .col-sm-8-9 {
    width: 88.8888%;
  }
  .col-sm-8-10 {
    width: 80%;
  }

  .col-sm-9-10 {
    width: 90%;
  }
}

/*
	* > 768px e < 1024px
*/
@media (min-width: 48em) and (max-width: 64em) {
  .col-md-1 {
    width: 100% !important;
  }

  .col-md-hide {
    display: none !important;
  }
  .col-md-show {
    display: block !important;
  }

  .col-md-2,
  .col-md-1-2,
  .col-md-5-10,
  .col-md-4-8,
  .col-md-3-6,
  .col-md-2-4 {
    width: 50%;
  }

  .col-md-3,
  .col-md-1-3,
  .col-md-3-9,
  .col-md-2-6 {
    width: 33.3333%;
  }

  .col-md-4,
  .col-md-1-4,
  .col-md-2-8 {
    width: 25%;
  }

  .col-md-5,
  .col-md-1-5,
  .col-md-2-10 {
    width: 20%;
  }

  .col-md-6,
  .col-md-1-6 {
    width: 16.6666%;
  }

  .col-md-7,
  .col-md-1-7 {
    width: 14.2857%;
  }

  .col-md-8,
  .col-md-1-8 {
    width: 12.5%;
  }

  .col-md-9,
  .col-md-1-9 {
    width: 11.1111%;
  }

  .col-md-10,
  .col-md-1-10 {
    width: 10%;
  }

  .col-md-2-3 {
    width: 66.6666%;
  }

  .col-md-2-5 {
    width: 40%;
  }

  .col-md-2-7 {
    width: 28.5714%;
  }

  .col-md-2-9 {
    width: 22.2222%;
  }

  .col-md-3-4 {
    width: 75%;
  }
  .col-md-3-5 {
    width: 60%;
  }

  .col-md-3-7 {
    width: 42.8571%;
  }
  .col-md-3-8 {
    width: 37.5%;
  }

  .col-md-3-10 {
    width: 30%;
  }

  .col-md-4-5 {
    width: 80%;
  }
  .col-md-4-6 {
    width: 66.6666%;
  }
  .col-md-4-7 {
    width: 57.1428%;
  }

  .col-md-4-9 {
    width: 44.4444%;
  }
  .col-md-4-10 {
    width: 40%;
  }

  .col-md-5-6 {
    width: 83.3333%;
  }
  .col-md-5-7 {
    width: 71.4285%;
  }
  .col-md-5-8 {
    width: 62.5%;
  }
  .col-md-5-9 {
    width: 55.5555%;
  }

  .col-md-6-7 {
    width: 82.7142%;
  }
  .col-md-6-8 {
    width: 75%;
  }
  .col-md-6-9 {
    width: 66.6666%;
  }
  .col-md-6-10 {
    width: 60%;
  }

  .col-md-7-8 {
    width: 87.5%;
  }
  .col-md-7-9 {
    width: 77.7777%;
  }
  .col-md-7-10 {
    width: 70%;
  }

  .col-md-8-9 {
    width: 88.8888%;
  }
  .col-md-8-10 {
    width: 80%;
  }

  .col-md-9-10 {
    width: 90%;
  }
}

/*
	* 1024px e 1280px
*/

@media (min-width: 64em) and (max-width: 80em) {
  .col-lg-1 {
    width: 100% !important;
  }

  .col-lg-hide {
    display: none !important;
  }
  .col-lg-show {
    display: block !important;
  }

  .col-lg-2,
  .col-lg-1-2,
  .col-lg-5-10,
  .col-lg-4-8,
  .col-lg-3-6,
  .col-lg-2-4 {
    width: 50%;
  }

  .col-lg-3,
  .col-lg-1-3,
  .col-lg-3-9,
  .col-lg-2-6 {
    width: 33.3333%;
  }

  .col-lg-4,
  .col-lg-1-4,
  .col-lg-2-8 {
    width: 25%;
  }

  .col-lg-5,
  .col-lg-1-5,
  .col-lg-2-10 {
    width: 20%;
  }

  .col-lg-6,
  .col-lg-1-6 {
    width: 16.6666%;
  }

  .col-lg-7,
  .col-lg-1-7 {
    width: 14.2857%;
  }

  .col-lg-8,
  .col-lg-1-8 {
    width: 12.5%;
  }

  .col-lg-9,
  .col-lg-1-9 {
    width: 11.1111%;
  }

  .col-lg-10,
  .col-lg-1-10 {
    width: 10%;
  }

  .col-lg-2-3 {
    width: 66.6666%;
  }

  .col-lg-2-5 {
    width: 40%;
  }

  .col-lg-2-7 {
    width: 28.5714%;
  }

  .col-lg-2-9 {
    width: 22.2222%;
  }

  .col-lg-3-4 {
    width: 75%;
  }
  .col-lg-3-5 {
    width: 60%;
  }

  .col-lg-3-7 {
    width: 42.8571%;
  }
  .col-lg-3-8 {
    width: 37.5%;
  }

  .col-lg-3-10 {
    width: 30%;
  }

  .col-lg-4-5 {
    width: 80%;
  }
  .col-lg-4-6 {
    width: 66.6666%;
  }
  .col-lg-4-7 {
    width: 57.1428%;
  }

  .col-lg-4-9 {
    width: 44.4444%;
  }
  .col-lg-4-10 {
    width: 40%;
  }

  .col-lg-5-6 {
    width: 83.3333%;
  }
  .col-lg-5-7 {
    width: 71.4285%;
  }
  .col-lg-5-8 {
    width: 62.5%;
  }
  .col-lg-5-9 {
    width: 55.5555%;
  }

  .col-lg-6-7 {
    width: 82.7142%;
  }
  .col-lg-6-8 {
    width: 75%;
  }
  .col-lg-6-9 {
    width: 66.6666%;
  }
  .col-lg-6-10 {
    width: 60%;
  }

  .col-lg-7-8 {
    width: 87.5%;
  }
  .col-lg-7-9 {
    width: 77.7777%;
  }
  .col-lg-7-10 {
    width: 70%;
  }

  .col-lg-8-9 {
    width: 88.8888%;
  }
  .col-lg-8-10 {
    width: 80%;
  }

  .col-lg-9-10 {
    width: 90%;
  }
}

/*
	* ≥ 1280px
*/
@media (min-width: 80em) {
  .col-xl-1 {
    width: 100% !important;
  }

  .col-xl-hide {
    display: none !important;
  }
  .col-xl-show {
    display: block !important;
  }

  .col-xl-2,
  .col-xl-1-2,
  .col-xl-5-10,
  .col-xl-4-8,
  .col-xl-3-6,
  .col-xl-2-4 {
    width: 50%;
  }

  .col-xl-3,
  .col-xl-1-3,
  .col-xl-3-9,
  .col-xl-2-6 {
    width: 33.3333%;
  }

  .col-xl-4,
  .col-xl-1-4,
  .col-xl-2-8 {
    width: 25%;
  }

  .col-xl-5,
  .col-xl-1-5,
  .col-xl-2-10 {
    width: 20%;
  }

  .col-xl-6,
  .col-xl-1-6 {
    width: 16.6666%;
  }

  .col-xl-7,
  .col-xl-1-7 {
    width: 14.2857%;
  }

  .col-xl-8,
  .col-xl-1-8 {
    width: 12.5%;
  }

  .col-xl-9,
  .col-xl-1-9 {
    width: 11.1111%;
  }

  .col-xl-10,
  .col-xl-1-10 {
    width: 10%;
  }

  .col-xl-2-3 {
    width: 66.6666%;
  }

  .col-xl-2-5 {
    width: 40%;
  }

  .col-xl-2-7 {
    width: 28.5714%;
  }

  .col-xl-2-9 {
    width: 22.2222%;
  }

  .col-xl-3-4 {
    width: 75%;
  }
  .col-xl-3-5 {
    width: 60%;
  }

  .col-xl-3-7 {
    width: 42.8571%;
  }
  .col-xl-3-8 {
    width: 37.5%;
  }

  .col-xl-3-10 {
    width: 30%;
  }

  .col-xl-4-5 {
    width: 80%;
  }
  .col-xl-4-6 {
    width: 66.6666%;
  }
  .col-xl-4-7 {
    width: 57.1428%;
  }

  .col-xl-4-9 {
    width: 44.4444%;
  }
  .col-xl-4-10 {
    width: 40%;
  }

  .col-xl-5-6 {
    width: 83.3333%;
  }
  .col-xl-5-7 {
    width: 71.4285%;
  }
  .col-xl-5-8 {
    width: 62.5%;
  }
  .col-xl-5-9 {
    width: 55.5555%;
  }

  .col-xl-6-7 {
    width: 82.7142%;
  }
  .col-xl-6-8 {
    width: 75%;
  }
  .col-xl-6-9 {
    width: 66.6666%;
  }
  .col-xl-6-10 {
    width: 60%;
  }

  .col-xl-7-8 {
    width: 87.5%;
  }
  .col-xl-7-9 {
    width: 77.7777%;
  }
  .col-xl-7-10 {
    width: 70%;
  }

  .col-xl-8-9 {
    width: 88.8888%;
  }
  .col-xl-8-10 {
    width: 80%;
  }

  .col-xl-9-10 {
    width: 90%;
  }
}

.header-menu {
  position: relative;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  width: auto;
  white-space: nowrap;
  display: none;
}
.shape-remove-shadow_white {
  position: absolute;
  background: #ffffff;
  height: 50px;
  width: 10px;
  right: -3px;
  top: 60px;
  display: none;
}

.header-topo {
  background-color: #ffffff;
  height: 60px;
  position: relative;
}
.header-controlMenuButton {
  position: absolute;
  left: 200px;
  height: 60px;
  width: 60px;
  background-color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.shape-remove-shadow_green {
  position: absolute;
  background: #ffffff;
  height: 60px;
  width: 10px;
  right: -3px;
  top: 0;
}
.menu-header {
  position: absolute;
  z-index: 10;
  border-bottom: none;
  width: 100%;
  background-color: #ffffff;
  box-shadow: none;
  padding: 25px 0px;
}

.header-topo {
  background-color: #ffffff;
  height: 60px;
  position: relative;
}
body {
  font-family: "Quicksand";
}
#header .brand-icon {
  position: initial;
  top: 50%;
  right: 50%;
  width: 100%;
  height: 24px;
  margin: -12px -40px 0 0;
  margin-left: 23%;
  padding: 21px;
}
.shape-remove-shadow_green {
  display: none;
  position: absolute;
  background: #ffffff;
  height: 60px;
  width: 10px;
  right: -3px;
  top: 0;
}

#menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 100;
  background-color: #ffffff;
  width: 222px;
}

.header-controlMenuButton {
  position: absolute;
  left: 200px;
  height: 60px;
  width: 60px;
  background-color: #f3f4f5;
  text-align: center;
  cursor: pointer;
  display: none;
}
#header .header-inner {
  /* margin-left: 260px; */
  position: relative;
  background-color: #ffffff;
  border-bottom: none;
  /* box-shadow: 0px 2px 2px 0px rgba(50, 50, 50, 0.1); */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0276834);
}

.header-topo {
  background-color: #ffffff;
  height: 70px;
  position: relative;
}

#header .brand-icon {
  position: initial;
  top: 50%;
  right: 50%;
  width: 100%;
  height: 24px;
  margin: -12px -40px 0 0;
  margin-left: 20%;
  padding: 26px;
}

.menu-box {
  overflow: auto;
  position: relative;
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.active {
  border-right: 33554221px solid #800020;
}

.button-menu-mobile span {
  position: absolute;
  width: 1.8rem;
  background-color: black;
  height: 3px;
  top: 0;
}
#header .stripe-top {
  margin-top: -8px;
}
#header .stripe-bottom {
  margin-top: 8px;
}
.searchBar {
  background: rgba(130, 130, 130, 0.07);
  border-radius: 4px;
  border: none;
  padding: 8px 28px;
  width: 200px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  color: #979797;
}
.button-menu-mobile span {
  position: absolute;
  width: 1.8rem;
  background-color: black;
  height: 3px;
  top: 10px;
}
.nameP {
  white-space: nowrap;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #1b253a;
  margin-left: 10px;
  margin-top: 10px;
}
.cardFactP {
  font-family: quicksand;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;

  color: #323232;
}

.iconDiv {
  width: 36px;
  height: 36px;
  background: rgba(128, 0, 32, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.iconDiv .div {
  display: flex;
  align-items: center;
}

#content .content-box {
  background-color: #f3f4f5;
  border-bottom: none;
  box-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
#content .content-wrapper {
  padding: 40px;
  margin: -4%;
}

#content .content-title {
  font-size: 24px;
  line-height: 28px;
  color: #008658;
  font-weight: 300;
  margin-bottom: 5px;
  display: block;
  font-family: quicksand;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: #021c1e;
  margin-bottom: 3%;
}
.cardFactDiv {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 208px;
  margin-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardFactDiv div {
 align-items: center !important;
}

.MuiDivider-root {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: rgb(243, 244, 245);
  width: 100%;
}
.MuiDivider-root {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: rgb(243, 244, 245);
}
.div1 {
  display: flex;
  width: 80%;
}
.cardFactDiv {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 208px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 23%;
}
#content .content-wrapper {
  padding: 40px;
  margin: -5% -6%;
}
.iconDiv {
  width: 36px;
  height: 36px;
  background: rgba(128, 0, 32, 0.1);
  display: flex;
  justify-content: center;
}

.d-flex-card {
  display: flex;
  justify-content: space-between;
  /* width: 60% */
}
.activityS {
  display: flex;
  justify-content: space-between;
  /* width: 97%; */
  margin-top: 10px;
  margin-right: 30px;
  /* max-height: 400px; */
}
.activitySDiv1 {
  margin-top: 10px;
  /* background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1); */
  border-radius: 4px;
  /* max-width: 380px; */
  margin-right: 10px;
  width: 35%;
  height: 200px;

  
}
.activitySDiv2 {
  margin-top: 10px;
  margin-right: 10px;
  /* max-width: 40%; */
  width: 100%;
  /* height: 600px; */
}

.body {
  height: 30vh;
}

.subbody {
  height: 9.999999vh;
  margin-bottom: 4.5%;
}

.activityTab {
  display: flex;
  justify-content: space-between;
  width: 97.7%;
  margin-top: 0.7%;
}
.activityTab1 {
  width: 49.9%;
}
.activityTab2 {
  width: 49.9%;
}
.activityTrans {
  width: 97.7%;
}
.p {
  font-family: quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #800020;
  cursor: pointer;
}
#content .content-inner {
  padding: 110px 0 0 260px;
  overflow: hidden;
}
.tabP {
  font-family: quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  padding: 0 17px;
  color: #262626;
}
.tabPB {
  font-family: quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 0 17px;
  color: #343434;
}
.arrow {
  width: 15%;
  border: 2px solid #f3f4f5;
  height: 65%;
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-left: 5%;
}
.desktop {
  display: flex;
  flex-direction: column;
}
.mobile {
  display: none;
}
.trans {
  font-family: quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #343434;
}

.subbody {
  height: 11.15vh;
  margin-bottom: 4.5%;
}

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  font-family: "Open Sans", "Helvetica Neue", "Segoe UI", "Calibri", "Arial",
    sans-serif;
  font-size: 18px;
  color: #60666d;
}
#content .content-wrapper {
  padding: 40px;
  margin: -5% -1%;
}

.secNav {
  background: rgb(255, 255, 255);
  margin-top: -6%;
  margin-left: -11%;
  padding: 10px;
  margin-bottom: 5%;
  width: 118%;
}

.secNav {
  background: rgb(255, 255, 255);
  margin-top: -6%;
  margin-left: -12%;
  padding: 10px;
  margin-bottom: 5%;
}
@media (min-width: 768px) {
  .loginDiv {
    width: unset ;
  }
  .select-box {
    width: 70%;
  }
}
@media (min-width: 992px) {
  .select-box {
    width: 50%;
  }

  .loginDiv {
    width: unset ;
  }
}
@media (min-width: 1200px) {
  .select-box {
    width: 30%;
  }
}
.select-box__current {
  position: relative;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
}
.select-box__current:focus + .select-box__list {
  opacity: 1;
  animation-name: none;
}
.select-box__current:focus + .select-box__list .select-box__option {
  cursor: pointer;
}
.select-box__current:focus .select-box__icon {
  transform: translateY(-50%) rotate(180deg);
}
.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 20px;
  opacity: 0.3;
  transition: 0.2s ease;
}
.select-box__value {
  display: flex;
}
.select-box__input {
  display: none;
}
.select-box__input:checked + .select-box__input-text {
  display: block;
}
.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #fff;
}
.select-box__list {
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  animation-name: HideList;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}
.select-box__option {
  display: block;
  padding: 15px;
  background-color: #fff;
}
.select-box__option:hover,
.select-box__option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}
@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
.select-box__current {
  position: relative;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  border: 2px solid #f4f4f4;
  background: #ffffff;
  border: 1.3px solid #979797;
  box-sizing: border-box;
  border-radius: 4px;
}
/* Styles for wrapping the search box */

@keyframes blink {
  49% {
    opacity: 0;
  }
  50% {
    oppacity: 1;
  }
  51% {
    oppacity: 1;
  }
  52% {
    opacity: 0;
  }
}
.search-box {
  position: relative;
}
.search-box__input {
  background-color: #6045ff;
  caret-color: #6045ff;
  color: #fff;
  border: 0;
  border-radius: 0.4em;
  padding: 0.25em 0.8em;
  font-size: 1em;
  box-shadow: 0 0 0.75em 0.25em rgba(96, 69, 255, 0.2);
  transition: box-shadow 0.5s ease;
}
.search-box__input:hover,
.search-box__input:focus {
  outline: 0;
  box-shadow: 0 0.5em 1.5em 0.25em rgba(96, 69, 255, 0.2);
}
.search-box__input + i {
  position: absolute;
  width: 0.125em;
  height: 70%;
  border-radius: 0.2em;
  background-color: #fff;
  left: 0.8em;
  top: 50%;
  transform: rotateZ(-45deg) translateY(-50%);
  transform-origin: top;
  transition: transform 0.5s cubic-bezier(0.4, -1, 0.6, 2);
}
.search-box__input + i::before {
  content: "";
  display: block;
  position: relative;
  top: 0em;
  left: -0.3em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 100%;
  border: 0.125em solid #fff;
  background-color: #6045ff;
  transition: transform 0.5s cubic-bezier(0.4, -1, 0.6, 2);
}
.search-box__input:focus + i {
  transform: rotateZ(0) translateY(-50%);
  animation: blink 1.1s infinite 0.5s;
}
.search-box__input:focus + i::before {
  transform: rotateY(90deg);
}
.search-box__input[value]:not([value=""]) {
  caret-color: #fff;
}
.search-box__input[value]:not([value=""]) + i {
  display: none;
}

.search-box__input {
  background-color: #ffffff;
  caret-color: #f3f4f5;
  color: #fff;
  border: 0;
  border-radius: 0.4em;
  padding: 0.25em 0.8em;
  font-size: 14px;
  box-shadow: none;
}
.search-box__input:hover,
.search-box__input:focus {
  outline: 0;
  box-shadow: none;
}
.search-box__input + i::before {
  content: "";
  display: block;
  position: relative;
  top: 0em;
  left: -0.3em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 100%;
  border: 0.125em solid #1890ff;
  background: white;
  transition: transform 0.5s cubic-bezier(0.4, -1, 0.6, 2);
}

.search-box__input {
  background-color: #ffffff;
  caret-color: #f3f4f5;
  color: black;
  border: 0;
  border-radius: 0.4em;
  padding: 1em 0.8em;
  font-size: 14px;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  /* identical to box height */

  /* Grey / grey-7 */

  color: #8c8c8c;
}
.search-box__input + i {
  position: absolute;
  width: 0.125em;
  height: 70%;
  border-radius: 0.2em;
  background: #1890ff;
  left: 0.8em;
  top: 50%;
  transform: rotateZ(-45deg) translateY(-50%);
  transform-origin: top;
  transition: transform 0.5s cubic-bezier(0.4, -1, 0.6, 2);
}

.search-box__input + i {
  position: absolute;
  width: 0.125em;
  height: 60%;
  border-radius: 0.2em;
  background: #1890ff;
  left: 6.8em;
  top: 50%;
  transform: rotateZ(-45deg) translateY(-50%);
  transform-origin: top;
  transition: transform 0.5s cubic-bezier(0.4, -1, 0.6, 2);
}

.select-box__current {
  position: relative;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  border: 2px solid #f4f4f4;
  background: #ffffff;
  border: 1.3px solid #979797;
  box-sizing: border-box;
  border-radius: 4px;
  height: 6.2vh;
}

.secNav {
  background: rgb(255, 255, 255);
  margin-top: -6%;
  margin-left: -5%;
  padding: 10px;
  margin-bottom: 5%;
}

@media (max-width: 800px) {
  .loginDiv {
    width: unset !important;
  }
}

@media (min-width: 1200px) {
  .select-box {
    width: 125px;
  }
}

.select-box__current {
  position: relative;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  border: 2px solid #f4f4f4;
  background: #ffffff;
  border: 1.3px solid #979797;
  box-sizing: border-box;
  border-radius: 4px;
  height: 6.2vh;
}

.select-box__current {
  position: relative;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  border: 2px solid #f4f4f4;
  background: #ffffff;
  border: px solid #979797;
  box-sizing: border-box;
  border-radius: 4px;
  height: 6.2vh;
}
.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #fff;
  font-size: 14px;
}

.inSecNav {
  display: flex;
  width: 30%;
}

.opDiv {
  background: rgba(128, 0, 32, 0.05);
  border-radius: 30px;
  margin-left: 16%;
  width: 30%;
  padding: 0.2px;
  color: rgb(128, 0, 32);
  margin-top: 9%;
  margin-bottom: 1%;
}

.select-box__current {
  position: relative;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  border: 2px solid #f4f4f4;
  background: #ffffff;
  border: px solid #979797;
  box-sizing: border-box;
  border-radius: 4px;
  height: 6.6vh;
}

.subNavLink {
  display: flex;
  justify-content: space-between;
  width: 17%;
}
.inputsW {
  border: 1px solid #a2a698;
  border-radius: 10px;
  padding: 18px 20px;
  width: 300px;
}
.inputsL {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #323030;
}
.btn {
  font-size: 15px;
  line-height: 18px;
  /* display: flex; */
  /* align-items: flex-end; */
  color: #ffffff;
  background: #800020;
  border-radius: 10px;
  width: 347px;
  padding: 18px;
}
.flex {
  display: flex;
  justify-content: space-between;
}

.uploadImg {
  margin-left: -43%;
  margin-top: 2%;
}
.uploadText {
  font-size: "18px";
  line-height: 21px;
  color: #800020;
  font-weight: 500;
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 88%;
}
.btn1 {
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  border: 1px solid #800020;
  box-sizing: border-box;
  border-radius: 10px;
  letter-spacing: 0.035em;
  padding: 11px 15px;

  color: #800020;
}
.userDetails {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px;
  border-radius: 4px;
  padding: 2.5% 5% 2.5%;
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
}
.userDetailsT {
  display: flex;
  justify-content: space-between;
}
.userCardDiv {
  background: #ffffff;
  border: 0.8px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  min-width: 19%;
  padding: 2%;
  margin: 1%;
  max-width: 19%;
}
.userCardNum {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  margin-left: 4%;
  color: #323030;
}
.userCardST {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  /* align-items: flex-end; */
  color: #323030;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* align-self: center; */
  /* align-content: center; */
  margin-top: 33%;
}
.userCardImg {
  width: 40%;
}
.userDetailsH {
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.addH {
  margin: 3% 4%;
}
.activitySN {
  display: flex;
  justify-content: space-between;
  width: 25%;
}
.activityDiv {
  display: flex;
  justify-content: space-between;
}

.userDetailsP {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px;
  border-radius: 4px;
  padding: 2.5% 5% 2.5%;
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
  /* justify-content: center; */
}
.inSecNavA {
  display: flex;
  width: 45%;
}
.subNavLinkA {
  display: flex;
  justify-content: space-between;
  width: 22%;
}

.loginDiv {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
}

.iconDiv {
  width: 36px;
  height: 36px;
  border-radius: 60%;
  display: flex;
  justify-content: center;
}
.secNav {
  background: rgb(255, 255, 255);
  margin-top: -6%;
  margin-left: -12%;
  padding: 10px;
  margin-bottom: 5%;
}

.select-box__current {
  position: relative;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  height: 6.8vh;
}

.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: transparent;
  font-size: 14px;
}
.active {
  border-right: 1398088.7rem solid #800020;
}

.canvasjs-chart-toolbar {
  display: none;
}
a.canvasjs-chart-credit {
  display: none;
}
canvas.canvasjs-chart-canvas {
  height: 190px !important;
}
a.canvasjs-chart-credit {
  display: none !important;
}

.subbody {
  height: 19.15vh;
  margin-bottom: 4.5%;
}

rect {
  fill: transparent !important;
}

.body {
  height: 46.5vh;
}

.subbody {
  height: 22.15vh;
  margin-bottom: 4.5%;
}

.body {
  height: 53.5vh;
}

#app {
  padding: 20px;
  font-family: robot, arial;
}
svg {
  fill: #000;
  fill-opacity: 0.3;
}

/* path {
  stroke: steelblue;
  stroke-width: 2px;
  fill: none;
} */

.axis {
  stroke: #000;
}

.axis-labels text {
  fill: #000;
  fill-opacity: 0.9;
  font-size: 12px;
  text-anchor: middle;
}

.axis-labels line {
  stroke: #000;
}

.gridline {
  opacity: 0.2;
}

#content .content-wrapper {
  padding: 11px;
  margin: -5% -1%;
}

.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 18px 5px;
  background-color: transparent;
  font-size: 14px;
}
.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 12px;
  opacity: 0.3;
  transition: 0.2s ease;
}

.body {
  height: 64.5vh;
}

.subbody {
  height: 34.15vh;
  margin-bottom: 4.5%;
}

.body {
  height: 81.5vh;
}

.secNav {
  background: #fff;
  margin-top: -2%;
  margin-left: -6%;
  padding: 10px;
  margin-bottom: 5%;
}

.secNav {
  background: #fff;
  margin-top: -4%;
  margin-left: -8%;
  padding: 10px;
  margin-bottom: 5%;
}

.tabP {
  font-family: quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.24px;
  padding: 0 17px;
  color: #262626;
  width: 17%;
  text-align: left;
}
.tabPB {
  font-family: quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 0 17px;
  color: #343434;
  width: 17%;
  text-align: left;
}

#upload {
  display: none;
}

.foo {
  display: block;
  position: relative;
  width: 300px;
  /* margin: auto; */
  cursor: pointer;
  border: 0;
  height: 60px;
  border-radius: 5px;
  outline: 0;
  margin-top: 5%;
  margin-left: -7%;
}

.foo:after {
  transition: 200ms all ease;
  /* border-bottom: 3px solid rgba(0,0,0,.2); */
  background: #ffffff;
  /* text-shadow: 0 2px 0 rgba(0,0,0,.2); */
  color: #802122;
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "Upload Currency Image";
  line-height: 60px;
  border-radius: 5px;
  font-size: "18px";
  line-height: 21px;
  color: #800020;
  font-weight: 500;
  margin-left: -11%;
  margin-bottom: 20%;
  /* margin-top: -21%; */
}

/* .loginDiv {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
  border-radius: 10px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
} */

@media screen and (max-width: 768px) {
  .foo {
    display: block;
    position: relative;
    width: 300px;
    margin: auto;
    cursor: pointer;
    border: 0;
    height: 60px;
    border-radius: 5px;
    outline: 0;
    margin-top: 5%;
    margin-left: 13%;
  }
  .iconDiv {
    width: 36px;
    height: 36px;
    background: rgba(128, 0, 32, 0.1);
    display: flex;
  }
  .loginDiv {
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
    border-radius: 10px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
  }
  .inSecNavA {
    display: flex;
    width: 93%;
  }
  .btn1 {
    font-size: 14px;
    line-height: 17px;
    border: 1px solid #800020;
    box-sizing: border-box;
    border-radius: 10px;
    letter-spacing: 0.035em;
    padding: 11px 15px;
    color: #800020;
    display: none;
  }
  .userDetailsP {
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px;
    border-radius: 4px;
    padding: 2.5% 5% 2.5%;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    flex-direction: column-reverse;
    /* justify-content: center; */
  }
  .userCardDiv {
    background: #ffffff;
    border: 0.8px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 15px;
    width: 100%;
    min-width: 46%;
    padding: 2%;
    margin: 2%;
    max-width: 19%;
  }
  .uploadImg {
    margin-left: 0;
    margin-top: 9%;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .inputsW {
    border: 1px solid #a2a698;
    border-radius: 10px;
    padding: 18px 20px;
    width: 82%;
  }
  .btn {
    font-size: 15px;
    line-height: 18px;
    /* display: flex; */
    /* align-items: flex-end; */
    color: #ffffff;
    background: #800020;
    border-radius: 10px;
    width: 98%;
    padding: 18px;
  }
  .uploadText {
    font-size: "18px";
    line-height: 21px;
    color: #800020;
    font-weight: 500;
    margin-left: 22%;
    margin-bottom: 20%;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
  .subNavLink {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }
  .subNavLinkA {
    display: flex;
    justify-content: space-between;
    width: 62%;
  }
  .opDiv {
    background: rgba(128, 0, 32, 0.05);
    border-radius: 30px;
    margin-left: 8%;
    width: 30%;
    padding: 0.2px;
    color: rgb(128, 0, 32);
    margin-top: 8%;
    margin-bottom: 2%;
  }
  .secNav {
    background: rgb(255, 255, 255);
    margin-top: -14%;
    margin-left: -5%;
    padding: 10px;
    margin-bottom: 5%;
  }
  .inSecNav {
    display: flex;
    width: 70%;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
    flex-direction: column;
  }

  .div1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  /* .searchBar {
    margin-left: -34%;
    width: 185px;
  } */
  #content .content-wrapper {
    padding: 20px 10px;
    margin-top: -8%;
    margin-left: 1%;
  }
  .active {
    border-right: 33554218px solid #800000;
  }
  .nameP {
    white-space: nowrap;
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: rgb(27, 37, 58);
    margin-left: 10px;
    margin-top: 10px;
    display: none;
  }
  .cardFactDiv {
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 127px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 46%;
    margin: 5px 10px 5px 0;
  }
  .activityS {
    display: flex;
    flex-direction: column;
  }
  .activitySDiv1 {
    width: 100%;
    margin-bottom: 23%;
  }
  .activitySDiv2 {
    width: 100%;
    margin-bottom: 23%;
  }
  .activityTab {
    display: flex;
    justify-content: space-between;
    width: 97.7%;
    margin-top: 0.7%;
    flex-direction: column;
  }
  .activityTab1 {
    width: 99.5%;
  }
  .activityTab2 {
    width: 99.5%;
  }
  .activityTab {
    display: flex;
    justify-content: space-between;
    width: 97.7%;
    margin-top: 0.7%;
    flex-direction: column;
    margin-top: -11%;
  }
  /* .searchBar {
    margin-left: -34%;
    width: 172px;
  } */
  .subbody {
    height: 42.15vh;
    margin-bottom: 4.5%;
  }
  .activitySDiv2 {
    width: 100%;
    margin-bottom: 25%;
  }
  rect {
    fill: transparent !important;
  }

  .body {
    height: 58.5vh;
  }
  .activitySDiv1 {
    width: 100%;
    margin-bottom: 89%;
  }

  #content .content-wrapper {
    padding: 24px;
    margin: -5% -1%;
    width: 95%;
  }
}

.VictoryContainer {
  position: inherit !important;
  width: 100% !important
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0;
}
.a-dropdown {
  position: relative;
  font-family: Helvetica, Arial, sans-serif;
  max-width: 400px;
}

.a-dropdown__arrow {
  position: absolute;
  top: 22px;
  right: 20px;
  transform: rotate(90deg);
  z-index: 2;
}
.-isOpened .a-dropdown__arrow {
  transform: rotate(-90deg);
}
.a-dropdown__selected {
  position: relative;
  padding: 4px 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-transform: uppercase;
  background: #fff;
  border: 2px solid #000;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
}
.loginDiv {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px;
  border-radius: 10px;
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  margin: -4% 0% 0%;
}

.a-dropdown {
  position: relative;
  font-family: Helvetica, Arial, sans-serif;
  max-width: 365px;
  width: 25%;
}

@media (min-width: 768px) {
  .a-dropdown__selected {
    padding: 13px 20px;
    font-size: 26px;
    line-height: 30px;
  }
}
.a-dropdown__items {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  padding: 15px 10px;
  margin-top: -5px;
  background: #fff;
  border: 2px solid #000;
  border-radius: 5px;
  z-index: 0;
  /* Hide the items when the select box is closed: */
}
@media (min-width: 768px) {
  .a-dropdown__items {
    padding: 25px 20px;
  }
}

.a-dropdown__item {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-transform: uppercase;
  cursor: pointer;
}
@media (min-width: 768px) {
  .a-dropdown__item {
    font-size: 26px;
    line-height: 30px;
  }
}

select#dropdown {
  background: #ffffff;
  border: 0.8px solid #979797;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12%;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  color: #979797;
}
.a-dropdown__arrow {
  position: absolute;
  top: 22px;
  right: 20px;
  transform: rotate(90deg);
  z-index: 2;
  display: none;
}

select#dropdown {
  background: #ffffff;
  border: 0.8px solid #979797;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12%;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  color: #979797;
  margin-top: 10%;
}

.modalDialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in;
  -moz-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
  pointer-events: none;
}
.modalDialog:target {
  opacity: 1;
  pointer-events: auto;
}
.modalDialog > div {
  max-width: 800px;
  width: 90%;
  position: relative;
  margin: 10% auto;
  padding: 20px;
  border-radius: 3px;
  background: #fff;
}
.close {
  font-family: Arial, Helvetica, sans-serif;
  background: #f26d7d;
  color: #fff;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -10px;
  width: 34px;
  height: 34px;
  text-decoration: none;
  font-weight: bold;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 1px 1px 3px #000;
  -webkit-box-shadow: 1px 1px 3px #000;
  box-shadow: 1px 1px 3px #000;
  padding-top: 5px;
}
.close:hover {
  background: #fa3f6f;
}

.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 10px 10px;
  background-color: transparent;
  font-size: 14px;
}
h1,
h2,
h3,
h4 {
  font-family: "Rubik", sans-serif !important;
}
p {
  font-family: "Rubik", sans-serif !important;
  font-size: 400;
}

#header {
  position: fixed;
  height: 65px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}
.tabPB {
  font-family: quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 0 17px;
  color: #343434;
  width: 17%;
  text-align: left;
}
.products {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  margin: 1% 1%;
  color: #94979b;
  cursor: pointer;
}


.products:hover{
 color: #800020;
}
.noData{
  padding: d;
  display: flex;
  justify-content: center;
  margin: 5%;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 10%;
}