
// Ant Search 
.ant-input-search-icon path {
    fill: #1890FF !important;
}
.anticon-search::before{
    display: none !important;
}


@primary-color: #810121;@layout-header-background: #fff;@bit-gray-color: #5f5f5f;